const menus = [
    {
        id: 1,
        name: 'Home',
        links: '#',
        
    },
    
]

export default menus;