import img1 from '../images/team/Tejas .jpeg'
import img2 from '../images/team/ujjwal.jpeg'
import img3 from '../images/team/ekansh.jpeg'
import img4 from '../images/team/anurag.jpg'
import img5 from '../images/team/Aditya Raj.jpeg'
import img6 from '../images/team/Ayush.jpeg'

const dataTeam = [
    {
        img: img1,
        name:'Tejas Kumar',
        position: 'Founder & CEO',
        linkedin: 'https://www.linkedin.com/in/tejas-kumar-brain/'
    },
    {
        img: img2,
        name:'Ujjwal Mathur',
        position: 'Founder & CMO',
        linkedin: 'https://www.linkedin.com/in/ujjwal-mathur-79686722b/'
    },
    {
        img: img3,
        name:'Ekansh Agarwal',
        position: 'Director & COO',
        linkedin: 'https://www.linkedin.com/in/ekansh-agarwal-689301250/'
    },
    {
        img: img4,
        name:'Anurag Tiwari',
        position: '',
        linkedin: 'https://www.linkedin.com/in/anurag-tiwari-6ab665256/'
    },
    {
        img: img5,
        name:'Aditya Raj',
        position: '',
        linkedin: 'https://www.linkedin.com/in/aditya-raj-a7a249253/'
    },
    {
        img: img6,
        name:'Ayush',
        position: '',
        linkedin: 'https://www.linkedin.com/in/ayush-kumar-gupta-7733a6280/'
    },
    // {
    //     img: img7,
    //     name:'Sarah Jenifer',
    //     position: 'Founder & CEO'
    // },
    // {
    //     img: img8,
    //     name:'James Smith',
    //     position: 'Founder & CEO'
    // },
    // {
    //     img: img9,
    //     name:'Paul Harrison',
    //     position: 'Founder & CEO'
    // },
    // {
    //     img: img10,
    //     name:'Sarah Jenifer',
    //     position: 'Founder & CEO'
    // },
    // {
    //     img: img11,
    //     name:'Mike Anderson',
    //     position: 'Founder & CEO'
    // },
    // {
    //     img: img12,
    //     name:'James Smith',
    //     position: 'Founder & CEO'
    // },
]

export default dataTeam;