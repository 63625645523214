const dataFaq = [
    {
        title: 'What kind of games can I generate?',
        text: 'You can generate a wide variety of games, including quizzes, puzzles, trivia challenges, word games, and more. The possibilities are virtually endless!',
        show: 'true'
    },
    {
        title: 'Are the games suitable for all ages?',
        text: 'Yes, the games are designed to be suitable for all ages, from children to adults. ',

    },
    {
        title: 'What topics or themes can I generate about?',
        text: 'You can generate games on any topic or theme you can imagine, including science, history, literature, pop culture, sports, geography, and more. The choice is yours!'
    },
    {
        title: 'Are the games accessible on different devices?',
        text: 'Yes, the games are accessible on various devices, including computers, tablets, and smartphones. You can enjoy playing them anytime, anywhere!'
    },
    {
        title: 'How many games can I generate?',
        text: " No, there's no limit to how many games you can generate. Feel free to generate as many games as you like and enjoy endless hours of fun and learning! "
    },
]

export default dataFaq;