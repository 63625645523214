import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import item from '../../assets/fake-data/item';

const SliderTwo = () => {
    const [datatext, setDataText] = useState({
        subtitle: 'Svar GameGen AI',
        title: '',
        desc: ''
    });

    const [currentTypeIndex, setCurrentTypeIndex] = useState(0);
    const [displayedText, setDisplayedText] = useState('');

    const types = [" a game" , " on any topic", "  Learn, have fun!"];

    useEffect(() => {
        let currentIndex = 0;
        let forward = true;
        const intervalId = setInterval(() => {
            if (forward) {
                if (currentIndex < types[currentTypeIndex].length) {
                    setDisplayedText((prevText) => prevText + types[currentTypeIndex][currentIndex]);
                    currentIndex++;
                } else {
                    forward = false;
                }
            } else {
                if (currentIndex > 0) {
                    setDisplayedText((prevText) => prevText.slice(0, -1));
                    currentIndex--;
                } else {
                    forward = true;
                    setCurrentTypeIndex((prevIndex) => (prevIndex + 1) % types.length);
                }
            }
        }, 100); // Change letter every 75 milliseconds

        return () => clearInterval(intervalId);
    }, [currentTypeIndex]);

    return (
        <section className="tf-section hero-slider style-2">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text pt-24 center">
                            <h6 className="sub-title mb-6" data-aos="fade-up">{datatext.subtitle}</h6>
                            <h2 className="title mb-26" data-aos="fade-up">Realize your ideas <br /> 
                                <span className="fixed-height-text" data-period="2000">Generate{displayedText}</span>
                            </h2>
                            <p className="desc mb-53" data-aos="fade-up">{datatext.desc}</p>
                            <a href="https://game.svar.in" className="btn-action style-2 fixed-button" data-aos="fade-up" data-aos-duration="1200">Start Generating</a>

                            <div className="shape">
                                {/* <div className="item bg-1"><img src={item.item11} alt="Svar" /></div>
                                <div className="item bg-2"><img src={item.item12} alt="Svar" /></div>
                                <div className="item bg-1"><img src={item.item13} alt="Svar" /></div>
                                <div className="item bg-1"><img src={item.item14} alt="Svar" /></div>
                                <div className="item bg-2"><img src={item.item6} alt="Svar" /></div>
                                <div className="item bg-2"><img src={item.item15} alt="Svar" /></div>
                                <div className="item bg-1"><img src={item.item16} alt="Svar" /></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SliderTwo;
