import React from 'react';
import Header from '../header/Header';  
const FlutterApp = () => {
  return (
    <div>
       <Header />
       <h1>Flutter App</h1>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <iframe
        title="Flutter App"
        src="https://flutter-engine.vercel.app/" // Replace with the URL of your locally hosted Flutter web app
        style={{ width: '75%', height: '550px', border: "white 10px solid",borderRadius: '20px'  
        // center the iframe 
      }}
      ></iframe></div>
    </div>
  );
};

export default FlutterApp;