import img1 from '../images/icon/icon1.jpeg'
import img2 from '../images/icon/icon2.jpeg'
import img3 from '../images/icon/icon3.jpeg'
import img4 from '../images/icon/icon4.jpeg'

const dataCard = [
  {
    stt: '01',
    title: 'Science',
    desc: 'Studious? Want to understand gravity or light? Start learning with our GameGen AI.',
    class: '',
    class2: '',
    img: img1,
    backgroundImg: 'montenoreactjs/src/assets/images/background/backpacker-7628303_640.jpg', // Add background image path here
  },
  {
    stt: '02',
    title: 'Culture',
    desc: 'Want to live festivals from corners of India? Generate a game and Celebrate them with us.',
    class: '',
    class2: '',
    img: img2,
    backgroundImg: 'montenoreactjs/src/assets/images/background/backpacker-7628303_640.jpg', // Add background image path here
  },
  
  {
    stt: '03',
    title: 'Space',
    desc: 'Curious about planets, stars, and space exploration. Let’s explore together.',
    class: '',
    class2: '',
    img: img3,
    backgroundImg: 'montenoreactjs/src/assets/images/background/backpacker-7628303_640.jpg', // Add background image path here
  },

  {
    stt: '04',
    title: 'History',
    desc: 'Who came first? Who came second and fought which battle? Learn with a game.',
    class: '',
    class2: '',
    img: img4,
    backgroundImg: 'montenoreactjs/src/assets/images/background/backpacker-7628303_640.jpg', // Add background image path here
  },
  
 
  
]

export default dataCard;
