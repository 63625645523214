import React from 'react';
import { Link } from 'react-router-dom';


const Speciality2 = (props) => {
    const data = props.data ; // Use props.data if available, otherwise use the imported dataCard

    return (
        <section className="tf-section section-speciality">
            <div className="container">
            <div className="row">
    {data.map((item, index) => {
        // Generate a random margin-top value between 0 and 50 pixels
        const randomMarginTop = Math.floor(Math.random() * 81);
        
        return (
            <div key={index} className={`col-xl-3 col-md-6 col-10 ${item.class}`} style={{ marginTop: `${randomMarginTop}px` }}>
                <div className={`box-item bg-2 ${item.class2}`} data-aos="fade-up" data-aos-duration="800">
                    <div className="img-container">
                        <img src={item.img} alt={item.title} className="card-img" />
                        <h3 to="/collections" className="h5">{item.title}</h3>
                        <div className="overlay">
                            <div className="text">{item.desc}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    })}
</div>

            </div>
        </section>
    );
}

export default Speciality2;
